import { Avatar, styled, NoSsr } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import type { Property } from "csstype";
import { mergeArraysUnique } from "../../helpers/array";
import { ThemeChip } from "./ThemeComponents/ThemeChip";
import { Option as FilterOption, OptionValue } from "./Filter";
import { SeoAnchor } from "./anchor/SeoAnchor";
import { SeeMoreButton } from "./SeeMoreButton";
import { SSR } from "../../helpers/environment";
import { useReRenderOnResize } from "../../helpers/reRenderOnResize";

type Height = Property.Height<string | number>;

const animationTime = 250;

interface Props {
  options: FilterOption[];
  onChange: (checked: OptionValue[]) => void;
  initialSelectedOptionValues?: OptionValue[];
  maxRowsBeforeShowMore?: number;
  seoHrefPrefix?: string;
}

const margin = 8;
const defaultHeight = 200;

const OptionContainer = styled("div")(() => ({
  display: "inline-block",
}));

const ButtonContainer = styled("div")(() => ({
  textAlign: "center",
  marginTop: margin,
}));

const ClickableChip = styled(ThemeChip)(() => ({
  cursor: "pointer",
}));

const StyledChipAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: `${theme.palette.text.chipDefault} !important`,
}));

const OptionListContainer = styled("div")<{
  currentHeight: Height;
}>(({ currentHeight }) => ({
  height: currentHeight,
  overflow: "hidden",
  gap: margin,
  display: "flex",
  flexWrap: "wrap",
  textAlign: "inherit",
  transition: `${animationTime}ms ease-in-out`,
}));
interface OptionProps {
  option: FilterOption;
  initialSelectedOptionValues: OptionValue[];
  onChange: (checked: OptionValue[]) => void;
}
const Option = ({
  option: { value, detailsLabel, label },
  initialSelectedOptionValues,
  onChange,
}: OptionProps) => {
  const isChecked = initialSelectedOptionValues.includes(value);
  return (
    <ClickableChip
      onClick={() => {
        const newValues = !isChecked
          ? mergeArraysUnique(initialSelectedOptionValues, [value])
          : initialSelectedOptionValues.filter(
              (currentValue) => currentValue !== value,
            );
        onChange(newValues);
        return newValues;
      }}
      variant={isChecked ? "filled" : "outlined"}
      color="primary"
      label={label}
      avatar={
        detailsLabel ? (
          <StyledChipAvatar>{detailsLabel}</StyledChipAvatar>
        ) : undefined
      }
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const OptionList: FunctionComponent<Props> = ({
  options,
  onChange,
  seoHrefPrefix,
  initialSelectedOptionValues = [],
  maxRowsBeforeShowMore = 3,
}: Props) => {
  const [optionHeight, setOptionHeight] = useState(defaultHeight);
  const [containerHeight, setContainerHeight] = useState<Height | undefined>();
  const [open, setOpen] = useState(false);
  const [doneRendering, setDoneRendering] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  useReRenderOnResize();
  const rowHeight = optionHeight + margin;

  const heightByMaxRows = rowHeight * maxRowsBeforeShowMore;

  const allOptionsAreShowed =
    typeof containerHeight === "number" && heightByMaxRows > containerHeight;

  const collapsedHeight = allOptionsAreShowed
    ? containerHeight
    : heightByMaxRows;

  const showSeeMore = !hideButton && !allOptionsAreShowed;

  const currentContainerHeight = open
    ? containerHeight || "auto"
    : collapsedHeight - (showSeeMore ? margin : 0) || "auto";

  useEffect(() => {
    setDoneRendering(true);
  }, []);

  return (
    <>
      <div
        style={{
          overflow: doneRendering ? "visible" : "hidden",
          height: doneRendering ? "auto" : heightByMaxRows,
        }}
      >
        <OptionListContainer
          currentHeight={
            containerHeight && doneRendering ? currentContainerHeight : "auto"
          }
          ref={(node) => {
            if (node && !SSR) {
              setContainerHeight(hideButton ? "auto" : node.scrollHeight);
            }
          }}
        >
          {options.map((option, index) => (
            <OptionContainer
              key={`${option.value}${index}`}
              {...(!index
                ? {
                    ref: (node) => {
                      if (!SSR) {
                        setOptionHeight(node?.offsetHeight || defaultHeight);
                      }
                    },
                  }
                : {})}
            >
              {seoHrefPrefix ? (
                <SeoAnchor href={`${seoHrefPrefix}${option.value}`}>
                  <Option
                    option={option}
                    initialSelectedOptionValues={initialSelectedOptionValues}
                    onChange={onChange}
                  />
                </SeoAnchor>
              ) : (
                <Option
                  option={option}
                  initialSelectedOptionValues={initialSelectedOptionValues}
                  onChange={onChange}
                />
              )}
            </OptionContainer>
          ))}
        </OptionListContainer>
        {showSeeMore && (
          <NoSsr>
            <ButtonContainer>
              <SeeMoreButton
                onClick={() => {
                  setOpen(true);
                  setTimeout(() => setHideButton(true), animationTime);
                }}
              >
                See More
              </SeeMoreButton>
            </ButtonContainer>
          </NoSsr>
        )}
      </div>
    </>
  );
};

export default OptionList;
