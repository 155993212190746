import { styled } from "@mui/material";
import { FunctionComponent } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Main = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const OnlyDesktop: FunctionComponent<Props> = ({
  children,
  className,
}: Props) => <Main {...(className && { className })}>{children}</Main>;

export default OnlyDesktop;
