import { styled, Typography } from "@mui/material";
import { UserPopUpContainer, UserPopUpTitle } from "./VerifyEmailFormPopUp";

const StyledDescription = styled(Typography)(() => ({
  textAlign: "left",
  marginBottom: "1rem",
}));

const CommunityAccessPopUp = () => (
  <UserPopUpContainer>
    <UserPopUpTitle variant="h6">YOUR ACCOUNT IS UNDER REVIEW</UserPopUpTitle>
    <StyledDescription>
      A request has been sent to the owner of this marketplace, and access will
      be granted to you once approved.
    </StyledDescription>
  </UserPopUpContainer>
);

export default CommunityAccessPopUp;
