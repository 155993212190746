import { FunctionComponent, useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  lighten,
  styled,
  useTheme,
} from "@mui/material";
import NextLink from "next/link";
import { pageMapping } from "../../builder/mapping/page";
import { BackButton } from "./BackButton";
import { useConfiguration } from "../../dataAccess/api/configuration";

export type NavigationType = "breadcrumb" | "backButton";
interface Props {
  textColor?: string;
  navigation: {
    type?: NavigationType;
    links: {
      label?: string;
      href?: string;
    }[];
  };
}

const NavigationContainer = styled(Box)(({ theme }) => ({
  paddingTop: 0,
  alignSelf: "flex-start",
  [theme.breakpoints.up("md")]: {
    padding: `1rem ${theme.defaultSpacing.md.page.edges} ${theme.defaultSpacing.md.page.edges} 0`,
    marginBottom: 0,
  },
}));

const StyledNextLink = styled(NextLink)(() => ({
  textDecoration: "none",
  color: "inherit",
}));

const StyledLink = styled("a")(() => ({
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const StyledTypography = styled(Typography)(() => ({
  textTransform: "uppercase",
}));

const PageNavigation: FunctionComponent<Props> = ({
  textColor,
  navigation,
}) => {
  const theme = useTheme();
  const { configuration } = useConfiguration();
  const breadcrumbTextColor = lighten(theme.palette.text.default, 0.5);

  const content = useMemo(() => {
    if (navigation.type === "breadcrumb") {
      return (
        <Breadcrumbs>
          <StyledNextLink href={pageMapping.PLP.path} shallow>
            <Typography color={breadcrumbTextColor}>
              <StyledLink>{configuration?.name || "MARKETPLACE"}</StyledLink>
            </Typography>
          </StyledNextLink>

          {navigation.links
            ?.filter(({ label }) => label)
            ?.map(({ label, href }, index) =>
              href ? (
                <StyledNextLink href={href} shallow>
                  <Typography color={breadcrumbTextColor}>
                    <StyledLink>{label}</StyledLink>
                  </Typography>
                </StyledNextLink>
              ) : (
                <StyledTypography color={breadcrumbTextColor} key={index}>
                  {label}
                </StyledTypography>
              ),
            )}
        </Breadcrumbs>
      );
    }

    const [{ href }] = navigation.links;

    return (
      <NextLink
        href={href || pageMapping.PLP.path}
        passHref
        legacyBehavior
        shallow
      >
        <BackButton textColor={textColor} />
      </NextLink>
    );
  }, [navigation, breadcrumbTextColor, configuration?.name, textColor]);

  return <NavigationContainer>{content}</NavigationContainer>;
};

export default PageNavigation;
