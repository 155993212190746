import { FunctionComponent, useCallback, useMemo } from "react";
import { useQueryParams } from "../../dataAccess/QueryParams";
import {
  Partner,
  PartnerResponse,
  usePartners,
} from "../../dataAccess/api/partners";
import Filter, { MobileFilterProps, Option } from "../ui/Filter";
import { Alignment, Position } from "../../types/theme";
import { PublicComponentProps } from "./PublicComponentProps";
import { GridSettings } from "./Grid";

export interface Settings {
  showNumberOfResults: boolean;
  alignment: Exclude<Alignment, "center">;
  position: Position;
  isOpenOnMobile: boolean;
  isOpenOnDesktop: boolean;
  title: string;
}
// TODO: remove "any" type in the next lines
interface Props
  extends MobileFilterProps,
    PublicComponentProps<Settings, any, any, GridSettings> {
  partnerList?: PartnerResponse;
}
const PartnersFilter: FunctionComponent<Props> = ({
  partnerList,
  settings,
  mobile,
}: Props) => {
  const { partner_ids } = useQueryParams();
  const { loadingPartnerList } = usePartners();
  const partnerToOption = useCallback(
    ({ id: value, name: label, numberOfItems }: Partner): Option => ({
      value: String(value),
      label,
      detailsLabel: settings.showNumberOfResults
        ? String(numberOfItems || "")
        : "",
    }),
    [settings],
  );
  const defaultExpanded = useMemo(() => {
    if (partner_ids && partner_ids?.length > 0) {
      return true;
    }
    return mobile ? settings.isOpenOnMobile : settings.isOpenOnDesktop;
  }, [partner_ids]);

  return partnerList || loadingPartnerList ? (
    <Filter
      alignment={settings.alignment}
      options={[{ values: partnerList?.map(partnerToOption) || [] }]}
      title={settings.title || "Partners"}
      queryParam="partner_ids"
      variant={
        settings.position !== "vertical" ? "horizontal" : settings.position
      }
      mobile={mobile}
      loading={loadingPartnerList}
      defaultExpanded={defaultExpanded}
    />
  ) : null;
};

export default PartnersFilter;
