import { IconButton, styled } from "@mui/material";

export const CloseButton = styled(IconButton)(({ theme }) => {
  const spacing = 20;
  return {
    position: "absolute",
    zIndex: theme.zIndex.modal,
    right: spacing,
    top: spacing,
    color: theme.palette.text.paper,
    width: "1rem",
    height: "1rem",
    [theme.breakpoints.up("md")]: {
      width: "2rem",
      height: "2rem",
    },
  };
});
