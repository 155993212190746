import { Button, ButtonProps, styled } from "@mui/material";
import { ReactNode, Ref, forwardRef } from "react";

export const SeeMoreButton = styled(
  forwardRef(
    (
      props: ButtonProps & {
        children?: ReactNode;
        target?: string;
      },
      ref: Ref<HTMLButtonElement>,
    ) => <Button variant="text" ref={ref} {...props} />,
  ),
)();
