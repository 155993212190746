import { styled } from "@mui/material";
import { Fragment, FunctionComponent } from "react";
import SkeletonLoader from "./SkeletonLoader";
import { Position } from "../../types/theme";
import OnlyDesktop from "./OnlyDesktop";

const StyledSkeletonTitle = styled(SkeletonLoader)(() => ({
  margin: "1rem 0",
  maxWidth: "20rem",
}));

const StyledSkeletonContent = styled(SkeletonLoader)<{
  positionVariant: Position;
}>(({ positionVariant }) => ({
  marginBottom: `${positionVariant === "vertical" ? 1 : 2}rem`,
  height: `${positionVariant === "vertical" ? 15 : 6}rem`,
}));
interface Props {
  variant: Position;
  mobile?: boolean;
}
const FilterSkeleton: FunctionComponent<Props> = ({
  variant,
  mobile,
}: Props) => {
  const Wrapper = mobile ? Fragment : OnlyDesktop;
  return (
    <Wrapper>
      <StyledSkeletonTitle variant="text" />
      <StyledSkeletonContent variant="rectangular" positionVariant={variant} />
    </Wrapper>
  );
};

export default FilterSkeleton;
