import { ButtonProps, FormControl } from "@mui/material";
import {
  ComponentProps,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import DefaultPoper, { AxisPosition } from "./DefaultPoper";
import {
  ThemeButton,
  Props as ThemeButtonProps,
} from "./ThemeComponents/ThemeButton";

interface Props extends ButtonProps, ThemeButtonProps {
  ButtonComponent: FunctionComponent<
    ButtonProps & ComponentProps<typeof ThemeButton>
  >;
  popupContent: ReactNode;
  popupOpen?: boolean | null;
  setPopupOpen?: Dispatch<SetStateAction<boolean>> | null;
}

const lastMousePosition: AxisPosition = {
  x: 0,
  y: 0,
};
const ButtonPopup: FunctionComponent<Props> = ({
  ButtonComponent,
  popupContent,
  popupOpen,
  setPopupOpen,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  if (!popupOpen) {
    popupOpen = open;
  }

  if (!setPopupOpen) {
    setPopupOpen = setOpen;
  }

  const handleClick = () => {
    if (setPopupOpen) {
      setPopupOpen((prev) => !prev);
    }
  };

  return (
    <FormControl>
      <ButtonComponent
        {...props}
        ref={(node) => {
          setAnchorEl(node);
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (props?.onClick) {
            props.onClick(e);
          }
          lastMousePosition.x = e.clientX;
          lastMousePosition.y = e.clientY;
          handleClick();
        }}
      >
        {props.children || null}
      </ButtonComponent>
      <DefaultPoper
        anchorEl={anchorEl}
        position={lastMousePosition}
        open={!!popupOpen}
        onClose={() => setPopupOpen && setPopupOpen(false)}
      >
        {popupContent}
      </DefaultPoper>
    </FormControl>
  );
};

export default ButtonPopup;
