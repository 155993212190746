import { FunctionComponent, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { Icon, Link, styled, Typography } from "@mui/material";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { forgotPassword } from "../../dataAccess/api/user";
import { useNavigateSameTab } from "../../helpers/navigation";
import TextField from "./TextField";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import {
  FormContent,
  FormSection,
  StyledForm,
  StyledFormTitle,
} from "./SignUpForm";
import { FormFieldMessage } from "./FormFieldMessage";
import { TextButton } from "./TextButton";
import { CloseButton } from "./CloseButton";
import OnlyMobile from "./OnlyMobile";
import { ButtonProgress } from "./ButtonProgress";
import { pageMapping } from "../../builder/mapping/page";
import SSOIntegration from "./SSOIntegration";
import { SignupSettings } from "../pages/SignUp";
import {
  DEFAULT_SIGN_IN_VALUE,
  DEFAULT_SIGN_UP_VALUE,
} from "../../../theme/defaultConstants";

interface FormData {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  loading?: boolean;
  serverError?: string;
  label?: string;
  sublabel?: string;
  buttonLabel?: string;
  setOpenSignUpInProfilePopup?: () => void;
  closePopUp?: () => void;
}

const emailSchemaOptions = {
  email: yup
    .string()
    .transform((value) => value.trim())
    .email("Email must be a valid email")
    .required("Email is a required field"),
};

const passwordSchemaOptions = {
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password mast be at least 8 characters"),
};

const forgotPasswordSchema = yup.object().shape({
  ...emailSchemaOptions,
});

const schema = yup.object().shape({
  ...emailSchemaOptions,
  ...passwordSchemaOptions,
});

const StyledLink = styled(Link)(() => ({
  cursor: "pointer",
}));

const ForgotPasswordButton = styled(TextButton)(() => ({
  alignSelf: "flex-start",
}));

const SignUpLink = styled(Typography)(() => ({
  alignSelf: "center",
  marginTop: "1rem",
}));

const LoginForm: FunctionComponent<Props> = ({
  loading,
  onSubmit,
  serverError,
  label,
  sublabel,
  setOpenSignUpInProfilePopup,
  closePopUp,
  buttonLabel,
}: Props) => {
  const navigate = useNavigateSameTab();
  const { configuration } = useConfiguration();
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const { control, formState, handleSubmit, getValues, setError } =
    useForm<FormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        email: "",
        password: "",
      },
    });

  const onForgotPassword = async () => {
    try {
      await forgotPasswordSchema.validateSync(getValues());
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        setError("email", {
          message: e.message,
          type: e.path,
        });
      } else {
        console.error(e);
      }
      return;
    }

    setForgotPasswordLoading(true);

    await forgotPassword(getValues("email")?.trim()).json();

    setForgotPasswordLoading(false);
    navigate({
      pathname: "/accounts/forgot-password",
      query: { email: getValues("email") },
    });
  };

  const signupSettings = useMemo(
    () =>
      configuration?.publishedTemplate?.properties.pages.find(
        ({ id }) => id === "SignUp",
      )?.settings as SignupSettings,
    [configuration],
  );
  const signUpLabel = signupSettings?.buttonLabel || DEFAULT_SIGN_UP_VALUE;

  return (
    <StyledForm
      isOnPopup={!!setOpenSignUpInProfilePopup}
      onSubmit={handleSubmit(onSubmit)}
    >
      {closePopUp && (
        <OnlyMobile>
          <CloseButton onClick={closePopUp}>
            <Icon>close</Icon>
          </CloseButton>
        </OnlyMobile>
      )}
      <FormContent isOnPopup={!!setOpenSignUpInProfilePopup}>
        <StyledFormTitle variant="h1">
          {label || DEFAULT_SIGN_IN_VALUE}
        </StyledFormTitle>
        {sublabel && <StyledFormTitle subtitle>{sublabel}</StyledFormTitle>}
        <SSOIntegration />
        <FormSection>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                error={!!formState.errors.email}
                helperText={
                  formState.errors.email ? formState.errors.email.message : ""
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                type="password"
                fullWidth
                error={!!formState.errors.password}
                helperText={
                  formState.errors.password
                    ? formState.errors.password.message
                    : ""
                }
              />
            )}
          />
          <ForgotPasswordButton
            startIcon={
              forgotPasswordLoading ? (
                <ButtonProgress />
              ) : (
                <Icon>lock_reset</Icon>
              )
            }
            onClick={onForgotPassword}
          >
            Forgot Password
          </ForgotPasswordButton>
          <ThemeButton
            startIcon={loading ? <ButtonProgress /> : null}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            {buttonLabel || DEFAULT_SIGN_IN_VALUE}
          </ThemeButton>
          {configuration?.isOpenMarketplace && !setOpenSignUpInProfilePopup && (
            <ThemeButton href={pageMapping.PLP.path} variant="text" fullWidth>
              SKIP FOR NOW
            </ThemeButton>
          )}
          {serverError ? (
            <FormFieldMessage color="error">{serverError}</FormFieldMessage>
          ) : (
            <></>
          )}
        </FormSection>
        <SignUpLink>
          Don&apos;t have an account yet?{" "}
          {setOpenSignUpInProfilePopup ? (
            <StyledLink onClick={setOpenSignUpInProfilePopup}>
              {signUpLabel}
            </StyledLink>
          ) : (
            <StyledLink href={pageMapping.SignUp.path}>
              {signUpLabel}
            </StyledLink>
          )}
        </SignUpLink>
      </FormContent>
    </StyledForm>
  );
};

export default LoginForm;
