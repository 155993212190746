import { FunctionComponent, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Icon,
  Link,
  styled,
  Typography,
} from "@mui/material";
import { pageMapping } from "../../builder/mapping/page";
import { builtfirstCommunitySubdomain } from "../../helpers/navigation";
import { useConfiguration } from "../../dataAccess/api/configuration";
import TextField from "./TextField";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { FormFieldMessage } from "./FormFieldMessage";
import { CloseButton } from "./CloseButton";
import OnlyMobile from "./OnlyMobile";
import { ButtonProgress } from "./ButtonProgress";
import SSOIntegration from "./SSOIntegration";
import TermsText from "./TermsText";
import { LoginSettings } from "../pages/LogIn";
import {
  DEFAULT_SIGN_IN_VALUE,
  DEFAULT_SIGN_UP_VALUE,
} from "../../../theme/defaultConstants";

interface FormData {
  fullName: string;
  signUpOrganizationName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndConditions: boolean;
  subscribeToNewsletter: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  loading?: boolean;
  serverError?: string;
  isInvite?: boolean;
  label?: string;
  sublabel?: string;
  buttonLabel?: string;
  setOpenLoginInProfilePopUp?: () => void;
  closePopUp?: () => void;
}

const baseSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password mast be at least 8 characters"),
  passwordConfirmation: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  subscribeToNewsletter: yup.boolean(),
});

const fullFieldsSchema = baseSchema.shape({
  email: yup
    .string()
    .transform((value) => value.trim())
    .email("Email must be a valid email")
    .required("Email is a required field"),
  signUpOrganizationName: yup
    .string()
    .required("Company Name is a required field"),
});

const getStyledFormMaxWidth = (isOnPopup?: boolean) => {
  if (isOnPopup) {
    return "35rem";
  }

  return "auto";
};

export const StyledForm = styled("form")<{ isOnPopup?: boolean }>(({
  theme,
  isOnPopup,
}) => {
  const isCenterAlignment = theme.settings.lockScreens.alignment === "center";
  const padding = "4.3rem";

  return {
    padding: isOnPopup ? "1.4rem" : "2.5rem",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      paddingLeft: padding,
      paddingRight: padding,
      width: getStyledFormMaxWidth(isOnPopup),
      minHeight: isCenterAlignment ? "75%" : "auto",
      justifyContent: isCenterAlignment ? "center" : "normal",
      borderRadius: isCenterAlignment ? "0.6rem" : "0",
    },
  };
});

export const FormSection = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

export const FormContent = styled("div")<{
  isOnPopup?: boolean;
}>(({ theme, isOnPopup }) => ({
  width: "100%",
  maxWidth: isOnPopup
    ? undefined
    : Number(theme.settings.lockScreens.maxWidth || 800),
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  paddingLeft: "1rem",
}));

const StyledLink = styled(Link)(() => ({
  cursor: "pointer",
}));

export const StyledFormTitle = styled(Typography)<{
  subtitle?: boolean;
}>(({ theme, subtitle }) => ({
  alignSelf: "center",
  fontSize: theme.typography[subtitle ? "body1" : "h2"].fontSize,
}));

const SignUpLink = styled(Typography)(() => ({
  alignSelf: "center",
  marginTop: "1rem",
}));

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  lineHeight: "1.3",
  fontSize: theme.typography.body2.fontSize,
}));

const SignUpForm: FunctionComponent<Props> = ({
  loading,
  onSubmit,
  serverError,
  label,
  sublabel,
  isInvite,
  setOpenLoginInProfilePopUp,
  closePopUp,
  buttonLabel,
}: Props) => {
  const { configuration } = useConfiguration();
  let validationSchema = isInvite ? baseSchema : fullFieldsSchema;
  if (!configuration?.customDomain) {
    validationSchema = validationSchema.shape({
      termsAndConditions: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept terms and conditions before continuing.",
        ),
    });
  }
  const { control, formState, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: "",
      signUpOrganizationName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      termsAndConditions: false,
      subscribeToNewsletter: false,
    },
  });

  const loginSettings = useMemo(
    () =>
      configuration?.publishedTemplate?.properties.pages.find(
        ({ id }) => id === "Login",
      )?.settings as LoginSettings,
    [configuration],
  );

  const loginButtonLabel = loginSettings?.buttonLabel || DEFAULT_SIGN_IN_VALUE;

  return (
    <StyledForm
      isOnPopup={!!setOpenLoginInProfilePopUp}
      onSubmit={handleSubmit(onSubmit)}
    >
      {closePopUp && (
        <OnlyMobile>
          <CloseButton onClick={closePopUp}>
            <Icon>close</Icon>
          </CloseButton>
        </OnlyMobile>
      )}
      <FormContent isOnPopup={!!setOpenLoginInProfilePopUp}>
        <StyledFormTitle variant="h1">
          {label || DEFAULT_SIGN_UP_VALUE}
        </StyledFormTitle>
        {sublabel && <StyledFormTitle subtitle>{sublabel}</StyledFormTitle>}
        <SSOIntegration />
        <FormSection>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Full Name"
                fullWidth
                error={!!formState.errors.fullName}
                helperText={
                  formState.errors.fullName
                    ? formState.errors.fullName.message
                    : ""
                }
              />
            )}
          />
          {!isInvite && (
            <Controller
              name="signUpOrganizationName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Company Name"
                  fullWidth
                  error={!!formState.errors.signUpOrganizationName}
                  helperText={
                    formState.errors.signUpOrganizationName
                      ? formState.errors.signUpOrganizationName.message
                      : ""
                  }
                />
              )}
            />
          )}
          {!isInvite && (
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  error={!!formState.errors.email}
                  helperText={
                    formState.errors.email ? formState.errors.email.message : ""
                  }
                />
              )}
            />
          )}
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                type="password"
                fullWidth
                error={!!formState.errors.password}
                helperText={
                  formState.errors.password
                    ? formState.errors.password.message
                    : ""
                }
              />
            )}
          />
          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirm Password"
                type="password"
                fullWidth
                error={!!formState.errors.passwordConfirmation}
                helperText={
                  formState.errors.passwordConfirmation
                    ? formState.errors.passwordConfirmation.message
                    : ""
                }
              />
            )}
          />
          {!configuration?.customDomain && (
            <Controller
              name="termsAndConditions"
              control={control}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label={
                      <CheckboxLabel>
                        <TermsText />
                      </CheckboxLabel>
                    }
                  />
                  {formState.errors.termsAndConditions && (
                    <StyledFormHelperText error>
                      {formState.errors.termsAndConditions.message}
                    </StyledFormHelperText>
                  )}
                </div>
              )}
            />
          )}
          {!configuration?.customDomain &&
            configuration?.subdomain === builtfirstCommunitySubdomain && (
              <Controller
                name="subscribeToNewsletter"
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        <CheckboxLabel>
                          Subscribe me to the Builtfirst newsletter
                        </CheckboxLabel>
                      }
                    />
                    {formState.errors.subscribeToNewsletter && (
                      <StyledFormHelperText error>
                        {formState.errors.subscribeToNewsletter.message}
                      </StyledFormHelperText>
                    )}
                  </div>
                )}
              />
            )}
          <ThemeButton
            startIcon={loading ? <ButtonProgress /> : null}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            {buttonLabel || DEFAULT_SIGN_UP_VALUE}
          </ThemeButton>
          {configuration?.isOpenMarketplace && !setOpenLoginInProfilePopUp && (
            <ThemeButton href={pageMapping.PLP.path} variant="text" fullWidth>
              SKIP FOR NOW
            </ThemeButton>
          )}
          {serverError ? (
            <FormFieldMessage color="error">{serverError}</FormFieldMessage>
          ) : (
            <></>
          )}
        </FormSection>
        <SignUpLink>
          Already have an account?{" "}
          {setOpenLoginInProfilePopUp ? (
            <StyledLink onClick={setOpenLoginInProfilePopUp}>
              {loginButtonLabel}
            </StyledLink>
          ) : (
            <StyledLink href={pageMapping.Login.path}>
              {loginButtonLabel}
            </StyledLink>
          )}
        </SignUpLink>
      </FormContent>
    </StyledForm>
  );
};

export default SignUpForm;
