import { NoSsr, styled } from "@mui/material";
import { FunctionComponent } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Main = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const OnlyMobile: FunctionComponent<Props> = ({
  children,
  className,
}: Props) => (
  <NoSsr>
    <Main {...(className && { className })}>{children}</Main>
  </NoSsr>
);

export default OnlyMobile;
