import { FunctionComponent } from "react";
import {
  styled,
  TextField as MUITextField,
  TextFieldProps,
  lighten,
  alpha,
} from "@mui/material";
import { InputSettings, Size } from "../../types/theme";
import { defaultAlphaConstant } from "../../../theme/defaultValues";
import { useCapitalization } from "../../builder/Theme";
import { deepMergeIgnoreUndefined } from "../../helpers/object";
import { BodySizes } from "../../builder/useFontsLayout";

interface Props {
  settingsOverride?: InputSettings;
}

export const inputSizes: Record<Size["size"], number> = {
  extraSmall: 0.8,
  small: 0.9,
  medium: 1,
  large: 1.3,
  extraLarge: 1.5,
};

const StyledTextField = styled(MUITextField)<Props>(({
  theme,
  label,
  settingsOverride,
}) => {
  const inputs = settingsOverride
    ? deepMergeIgnoreUndefined(theme.settings.inputs, settingsOverride)
    : theme.settings.inputs;
  const textTransform = useCapitalization(
    inputs?.capitalization === "Ag" ? undefined : inputs?.capitalization,
  );

  const inputSize = theme.spacing(inputSizes[inputs.size]);
  return {
    "& .MuiFormControl-root.MuiTextField-root": {
      marginBottom: "0rem !important",
    },
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      padding: `${theme.spacing(0.6)} !important`,
      fontFamily: theme.settings.fonts.body.fontFamily,
      borderStyle: "solid",
      fontSize:
        theme.typography[
          BodySizes[theme.settings.fonts.inputs?.size || "medium"]
        ].fontSize,
      borderWidth: inputs.border.thickness,
      borderRadius: inputs.border.radius * 5,
      borderColor: alpha(
        theme.palette.inputsUnderline.main,
        inputs.border.opacity,
      ),
      backgroundColor: theme.palette.inputs.default,
      color: theme.palette.text.inputDefault,
      "&:before, &:after": {
        borderBottom: inputs.showUnderline ? "" : "none !important",
      },
      "&:hover": {
        backgroundColor: theme.palette.inputs.default,
      },
      "& input, & textarea": {
        textTransform,
        paddingBottom: `${inputSize} !important`,
        ...(label
          ? {
              paddingTop: `calc(${inputSize} + 10px) !important`,
            }
          : {}),
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: theme.palette.inputs.default,
    },
    "& .MuiInputLabel-root": {
      left: theme.spacing(0.6),
      fontFamily: theme.settings.fonts.body.fontFamily,
      transition: "0.2s",
      fontSize: `calc(${
        theme.typography[
          BodySizes[theme.settings.fonts.inputs?.size || "medium"]
        ].fontSize
      } * 1.05)`,
      color: lighten(
        theme.palette.text.inputDefault,
        defaultAlphaConstant.alphaLight,
      ),
      "&:not(.MuiInputLabel-shrink)": {
        top: "50%",
        transform: "translate(12px, -50%) scale(1)",
      },
    },
    "& textarea": {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "0rem",
    },
  };
});

const TextField: FunctionComponent<TextFieldProps & Props> = (props) => (
  <StyledTextField variant="filled" {...props} />
);

export default TextField;
