import { CSSObject } from "@emotion/react";
import { alpha, Chip, styled, Theme } from "@mui/material";
import { Size } from "../../../types/theme";
import { useCapitalization, useShadows } from "../../../builder/Theme";
import { BodySizes } from "../../../builder/useFontsLayout";

export function getThemeChipPadding(paddingSize: number, theme: Theme) {
  const verticalPadding = theme.spacing(paddingSize / 4);
  const horizontalPadding = theme.spacing(paddingSize / 1.5);
  return {
    padding: verticalPadding,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  };
}

export const ThemeChip = styled(Chip)(({ theme }) => {
  const { chips, fonts } = theme.settings;
  const textTransform = useCapitalization(chips?.capitalization);
  const boxShadow = useShadows(chips?.shadows);

  function getPadding(paddingSize: number) {
    const sidePadding = theme.spacing(paddingSize / 2.3);
    return {
      padding: theme.spacing(paddingSize),
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
    };
  }

  const sizes: Record<Size["size"], CSSObject> = {
    extraSmall: getPadding(0.875),
    small: getPadding(2),
    medium: getPadding(2.5),
    large: getPadding(3),
    extraLarge: getPadding(3.5),
  };

  return {
    ...sizes[chips.size],
    textTransform,
    boxShadow,
    borderStyle: "solid",
    borderWidth: chips.border.thickness,
    borderRadius: chips.border.radius * 10,
    borderColor: alpha(theme.palette.text.chipDefault, chips.border.opacity),
    backgroundColor: theme.palette.chipsBackground.default,
    color: theme.palette.text.chipDefault,
    "&.MuiChip-outlined": {
      "&:hover": {
        color: `${theme.palette.chipsBackground.default}`,
        backgroundColor: `${theme.palette.text.chipDefault}`,
        "& .MuiAvatar-root": {
          backgroundColor: `${theme.palette.chipsBackground.default} !important`,
          color: theme.palette.text.chipDefault,
        },
      },
    },
    "& .MuiAvatar-root": {
      color: theme.palette.chipsBackground.default,
    },
    "&.MuiChip-filled": {
      color: theme.palette.chipsBackground.default,
      backgroundColor: theme.palette.text.chipDefault,
      "& .MuiAvatar-root": {
        backgroundColor: `${theme.palette.chipsBackground.default} !important`,
        color: theme.palette.text.chipDefault,
      },
    },
    "& .MuiChip-label": {
      fontSize:
        theme.typography[BodySizes[fonts.chips?.size || "medium"]].fontSize,
    },
  };
});
