import { styled, Typography } from "@mui/material";
import { useState } from "react";
import { ServerError } from "../../dataAccess/ServerError";
import { resendVerificationEmail } from "../../dataAccess/api/user";
import { FormFieldMessage } from "./FormFieldMessage";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { ButtonProgress } from "./ButtonProgress";

interface Props {
  email?: string;
}

export const UserPopUpContainer = styled("form")(({ theme }) => ({
  padding: "1rem",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up("md")]: {
    minWidth: "25rem",
  },
}));

export const UserPopUpTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: "black",
  marginBottom: "0.3rem",
  fontSize: theme.typography.h6.fontSize,
}));

const StyledDescription = styled(Typography)(() => ({
  textAlign: "left",
  marginBottom: "1rem",
}));

const StyledButton = styled(ThemeButton)(() => ({
  alignSelf: "stretch",
}));

const VerifyEmailFormPopUp = ({ email }: Props) => {
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    let response;
    if (email) {
      response = await resendVerificationEmail(email).json();
    } else {
      setServerError("There is no email to resend the verification.");
      return;
    }

    if (response instanceof ServerError) {
      const errorResponse = response;
      setServerError(errorResponse.getMessage());
      setLoading(false);

      return;
    }
    setSuccessMessage("Email has been sent successfully.");
    setLoading(false);
  };

  return (
    <UserPopUpContainer onSubmit={onSubmit}>
      <UserPopUpTitle variant="h1">PLEASE VERIFY YOUR EMAIL</UserPopUpTitle>
      <StyledDescription>
        We&apos;ve sent a verification link to {email}.
      </StyledDescription>
      <StyledButton
        startIcon={loading ? <ButtonProgress /> : null}
        variant="contained"
        type="submit"
        disabled={loading}
      >
        RESEND VERIFICATION EMAIL
      </StyledButton>
      {serverError && (
        <FormFieldMessage color="error">{serverError}</FormFieldMessage>
      )}
      {successMessage && (
        <FormFieldMessage color="success">{successMessage}</FormFieldMessage>
      )}
    </UserPopUpContainer>
  );
};

export default VerifyEmailFormPopUp;
