import useSWR from "swr";
import { defaultFetcher } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { buildUrlWithQueryParams } from "../QueryParams";

export type PartnerResponse = Partner[];

export interface Partner {
  id: number;
  name: string;
  numberOfItems?: number;
}

const base = `/items/sellers`;

export function getPartners() {
  const url = buildUrlWithQueryParams(base, {
    "fields[manager_organization]": "name,number_of_items",
  });
  const request = () => APIV3.get<JSONAPIDocument<PartnerResponse>>(url);
  return {
    json: () => defaultFetcher(request, serializers.partner),
    fallbackKey: url,
  };
}

export function usePartners() {
  const { fallbackKey, json } = getPartners();
  const { data, isValidating } = useSWR(fallbackKey, json);
  return {
    loadingPartnerList: isValidating,
    partnerList: data,
  };
}
