import { styled, Typography } from "@mui/material";
import Image from "next/legacy/image";

type EncasedImageVariantType = "circle" | "roundedBox" | "logo";

interface Props {
  variant: EncasedImageVariantType;
  src: string;
  width: number;
  height: number;
  initials?: string;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.inputDefault,
}));

const Main = styled("div")<{
  variant: EncasedImageVariantType;
  width: number;
  height: number;
}>(({ variant, width, height, theme }) => {
  const stylesByVariant = {
    circle: {
      backgroundColor: theme.palette.inputs.default,
      borderRadius: "50%",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.inputDefault,
    },
    roundedBox: {
      backgroundColor: theme.palette.inputs.default,
      borderRadius: "1rem",
    },
    logo: {
      backgroundColor: "white",
    },
  };

  return {
    width: `${width}px`,
    height: `${height}px`,
    display: "flex",
    ...stylesByVariant[variant],
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };
});

const EncasedImage = ({ variant, src, width, height, initials }: Props) => (
  <Main width={width} height={height} variant={variant}>
    {src ? (
      <Image src={src} width={width} height={height} />
    ) : (
      <StyledTypography>{initials}</StyledTypography>
    )}
  </Main>
);

export default EncasedImage;
