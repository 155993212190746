import { alpha, Skeleton, SkeletonProps, styled } from "@mui/material";
import { FunctionComponent } from "react";

type Props = SkeletonProps;

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.panelsBackground.default, 0.5),
  width: "100%",
}));

const SkeletonLoader: FunctionComponent<Props> = (props: Props) => (
  <StyledSkeleton animation="wave" {...props} />
);
export default SkeletonLoader;
