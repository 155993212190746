import Head from "next/head";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useConfiguration } from "src/dataAccess/api/configuration";
import useFontsLayout from "./useFontsLayout";
import { getIconsUrl } from "./icons";

interface Props {
  titleSufix?: string;
}

const CommonHead = ({ titleSufix }: Props) => {
  const { appFontsTag, googleFontsTag } = useFontsLayout();
  const theme = useTheme();
  const { configuration } = useConfiguration();
  const [hiddenIcons, setHiddenIcons] = useState(true);

  useEffect(() => {
    if (theme.settings.iconSet) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = getIconsUrl(theme.settings.iconSet);
      document.head.appendChild(link);
      setHiddenIcons(false);
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [theme.settings.iconSet]);

  const titlePrefixContent = `${
    theme.settings?.pagesTitlePrefix || configuration?.name
  }`;
  const titleSufixContent = titleSufix ? ` | ${titleSufix}` : "";

  const domain =
    configuration?.customDomain ||
    `https://${configuration?.subdomain}.builtfirst.com/`;
  const name = configuration?.name;

  return (
    <Head>
      <link rel="icon" href={theme.settings.favicon || "favicon.svg"} />
      {hiddenIcons && (
        <style>
          {`
            .MuiIcon-root {
              display: none;
            }
          `}
        </style>
      )}
      <title>{`${titlePrefixContent}${titleSufixContent}`}</title>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "${name}",
            "alternateName": ["${name} Marketplace"],
            "url" : "${domain}"
          }
        `,
        }}
      />
      {googleFontsTag}
      {appFontsTag}
    </Head>
  );
};

export default CommonHead;
