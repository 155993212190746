import {
  Icon,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { useRouter } from "next/router";
import {
  useAddQueryParams,
  useQueryParams,
} from "../../../dataAccess/QueryParams";
import DropdownList, { DropdownItem } from "../DropdownList";
import SavingsAsc from "../../../assets/svg/savings-desc.svg";
import AlphaAZ from "../../../assets/svg/alpha-a-z.svg";
import AlphaZA from "../../../assets/svg/alpha-z-a.svg";
import Popularity from "../../../assets/svg/popularity-icon.svg";
import OnlyDesktop from "../OnlyDesktop";
import OnlyMobile from "../OnlyMobile";

interface Props {
  options: DropdownItem[];
}

const SORT_QUERY_PARAM_KEY = "sorting";

const StyledDownArrowIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.default,
}));

const StyledSortIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.default,
}));

const StyledThemeColorIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.paper,
}));

const SortButtonDropdown: FunctionComponent<Props> = ({ options }: Props) => {
  const router = useRouter();
  const theme = useTheme();
  const setQueryParams = useAddQueryParams();
  const { sorting } = useQueryParams();

  const sortOptions: Record<
    DropdownItem["value"],
    { label: string; Icon: ReactNode }
  > = {
    "": {
      label: "",
      Icon: <></>,
    },
    recommended: {
      label: "Recommended",
      Icon:
        theme.settings.iconSet === "outlined" ? (
          <StyledThemeColorIcon>star_border</StyledThemeColorIcon>
        ) : (
          <StyledThemeColorIcon>star</StyledThemeColorIcon>
        ),
    },
    popular: {
      label: "Popularity",
      Icon: <Popularity fill={theme.palette.text.paper} />,
    },
    savings: {
      label: "Greatest Savings",
      Icon: <SavingsAsc fill={theme.palette.text.paper} />,
    },
    asc: {
      label: "Alphabetical (A to Z)",
      Icon: <AlphaAZ fill={theme.palette.text.paper} />,
    },
    desc: {
      label: "Alphabetical (Z to A)",
      Icon: <AlphaZA fill={theme.palette.text.paper} />,
    },
  };

  const selectedSortOptionByQueryParam = useMemo(
    () => options.find((option) => option.value === sorting),
    [router],
  );

  const [selectedSortOption, setSelectedSortOption] = useState<DropdownItem>(
    selectedSortOptionByQueryParam || {
      value: "",
      label: "",
    },
  );

  const handleChange = (event: SelectChangeEvent<DropdownItem>) => {
    const currentSortOption = options.find(
      (option) => option.value === event.target.value,
    );

    if (currentSortOption !== undefined) {
      setSelectedSortOption(currentSortOption);
      setQueryParams([
        {
          paramKey: "page_number",
          paramValue: null,
        },
        {
          paramKey: SORT_QUERY_PARAM_KEY,
          paramValue: currentSortOption.value,
        },
      ]);
    }
  };

  return (
    <DropdownList
      showIconAsLabel
      icon={
        <>
          <OnlyDesktop>
            <StyledDownArrowIcon>keyboard_arrow_down</StyledDownArrowIcon>
          </OnlyDesktop>
          <OnlyMobile>
            <StyledSortIcon>sort</StyledSortIcon>
          </OnlyMobile>
        </>
      }
      label={
        <>
          <OnlyDesktop>
            <Typography>Sort</Typography>
          </OnlyDesktop>
          <OnlyMobile>
            <></>
          </OnlyMobile>
        </>
      }
      options={options.map((option) => {
        const { Icon, label } = sortOptions[option.value];
        return {
          value: option.value,
          label,
          icon: Icon,
        };
      })}
      onChange={handleChange}
      selectedValue={{
        value: selectedSortOption.value,
        label: selectedSortOption.label,
        icon: sortOptions[selectedSortOption.value].Icon,
      }}
    />
  );
};

export default SortButtonDropdown;
