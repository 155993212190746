import { FunctionComponent, useCallback, useMemo } from "react";
import { useQueryParams } from "../../dataAccess/QueryParams";
import {
  Category,
  CategoryResponse,
  useCategories,
} from "../../dataAccess/api/categories";
import Filter, { MobileFilterProps, Option } from "../ui/Filter";
import { PublicComponentProps } from "./PublicComponentProps";
import { GridSettings } from "./Grid";
import { Settings } from "./PartnersFilter";

// TODO: remove "any" type in the next lines
interface Props
  extends MobileFilterProps,
    PublicComponentProps<Settings, any, any, GridSettings> {
  categories?: CategoryResponse;
}

const CategoriesFilter: FunctionComponent<Props> = ({
  categories,
  settings,
  mobile,
}: Props) => {
  const { category_ids } = useQueryParams();
  const { loadingCategoryList } = useCategories();
  const categoriesParents = categories?.filter(({ parent }) => !parent);
  const categoryToOption = useCallback(
    ({ id: value, name: label, numberOfItems }: Category): Option => ({
      value: String(value),
      label,
      detailsLabel: settings.showNumberOfResults
        ? String(numberOfItems || "")
        : "",
      subOptions: categories
        ?.filter(({ parent }) => parent === String(value))
        .map(categoryToOption),
    }),
    [settings, categories],
  );
  const defaultExpanded = useMemo(() => {
    if (category_ids && category_ids?.length > 0) {
      return true;
    }
    return mobile ? settings.isOpenOnMobile : settings.isOpenOnDesktop;
  }, [category_ids]);

  return categories?.length || loadingCategoryList ? (
    <Filter
      alignment={settings.alignment}
      options={[{ values: categoriesParents?.map(categoryToOption) || [] }]}
      title={settings.title || "Categories"}
      queryParam="category_ids"
      variant={
        settings.position !== "vertical" ? "horizontal" : settings.position
      }
      mobile={mobile}
      defaultExpanded={defaultExpanded}
      loading={loadingCategoryList}
    />
  ) : null;
};

export default CategoriesFilter;
