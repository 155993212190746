import useSWR from "swr";
import { defaultFetcher } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { buildUrlWithQueryParams } from "../QueryParams";

export type CategoryResponse = Category[];

export interface Category {
  id: number;
  name: string;
  parent?: string;
  numberOfItems?: number;
}

const base = `/items/categories`;

export function getCategories() {
  const url = buildUrlWithQueryParams(`${base}`, {
    "fields[category]": "name,number_of_items,parent",
  });
  const request = () => APIV3.get<JSONAPIDocument<CategoryResponse>>(url);
  return {
    json: () => defaultFetcher(request, serializers.category),
    fallbackKey: url,
  };
}

export function useCategories() {
  const { fallbackKey, json } = getCategories();
  const { data, isValidating } = useSWR(fallbackKey, json);
  return {
    loadingCategoryList: isValidating,
    categoryList: data,
  };
}
