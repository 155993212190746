import { Box, styled } from "@mui/material";
import { CSSProperties, FunctionComponent, ReactNode } from "react";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import { Alignment } from "../../types/theme";

export interface BannerConfiguration {
  layout: {
    layoutAlignment?: Alignment;
    backgroundImage?: string;
    backgroundImageMobile?: string;
    textColor?: string;
    backgroundColor?: string;
    minHeight?: number;
    backgroundImageMode?: "fit" | "fill" | "tile" | "crop";
    tileRepeat?: "repeatX" | "repeatY" | "both";
    contained?: boolean;
  };
}

interface Props extends BannerConfiguration {
  children: ReactNode;
  className?: string;
}

const Container = styled(Box)<BannerConfiguration>(({ layout, theme }) => {
  let backgroundImageModeStyles: CSSProperties = {};
  switch (layout?.backgroundImageMode) {
    case "fill":
      backgroundImageModeStyles = {
        backgroundSize: "cover",
      };
      break;
    case "fit":
      backgroundImageModeStyles = {
        backgroundSize: "contain",
      };
      break;
    case "tile":
      backgroundImageModeStyles = {
        backgroundRepeat: "no-repeat",
      };
      if (layout?.tileRepeat === "both") {
        backgroundImageModeStyles = {
          backgroundRepeat: "repeat",
        };
      }
      if (layout?.tileRepeat === "repeatX") {
        backgroundImageModeStyles = {
          backgroundRepeat: "repeat-x",
        };
      }
      if (layout?.tileRepeat === "repeatY") {
        backgroundImageModeStyles = {
          backgroundRepeat: "repeat-y",
        };
      }
      break;
    default:
      backgroundImageModeStyles = {};
  }

  return {
    width: !layout?.contained ? "100%" : "auto",
    backgroundColor: layout?.backgroundColor,
    ".MuiTypography-root": {
      color: layout?.textColor || theme.palette.text.default,
    },
    minHeight: layout?.minHeight && `${layout?.minHeight}px !important`,
    justifyContent: getJustificationByAlignment(layout?.layoutAlignment),
    alignItems: "center",
    backgroundImage: layout?.backgroundImageMobile
      ? `url(${layout.backgroundImageMobile})`
      : "none",
    backgroundSize: "cover",
    ...backgroundImageModeStyles,
    [theme.breakpoints.up("md")]: {
      backgroundImage: layout?.backgroundImage
        ? `url(${layout.backgroundImage})`
        : "none",
      backgroundSize: "auto",
    },
  };
});

const Banner: FunctionComponent<Props> = ({
  layout,
  children,
  ...props
}: Props) => (
  <Container layout={layout} {...props}>
    {children}
  </Container>
);

export default Banner;
