import { FunctionComponent, useEffect } from "react";
import { Icon, styled, useTheme } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import { sanitize } from "isomorphic-dompurify";
import { SSR } from "../../helpers/environment";
import { getIconsBaseClass } from "../../builder/icons";
import { useConfiguration } from "../../dataAccess/api/configuration";

interface Props {
  html: string;
}

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

const replaceIconNames = (
  baseClassNameIcons: string,
  str?: string,
): string | undefined => {
  const regex = /\{\{icon_(.*?)\}\}/g;

  return str?.replace(regex, (match, iconName) =>
    renderToStaticMarkup(
      <Icon baseClassName={baseClassNameIcons}>{iconName}</Icon>,
    ),
  );
};

const CustomHTML: FunctionComponent<Props> = ({ html }) => {
  const { configuration } = useConfiguration();
  const theme = useTheme();
  const baseClassNameIcons = getIconsBaseClass(theme.settings.iconSet);
  const extractedScript =
    /[\s\S]*?<script\b[^>]*>(.*?)<\/script\s*\/?>[\s\S]*/gis.exec(html);

  if (html && configuration?.mediaFiles) {
    configuration.mediaFiles?.forEach((mediaFile) => {
      // TODO: Create a helper to replace {{tags}}. This must be considered also in replaceSellerName function.
      const mediaFileLegendKey = `{{file_${mediaFile.legend}}}`;
      html = html?.replace(new RegExp(mediaFileLegendKey, "g"), mediaFile.url);
    });
  }

  const parsedHtml = replaceIconNames(baseClassNameIcons, html) || "";
  // TODO: Create a better way to handle scripts by adding a new configuration to
  // set the scripts of the application on the desired place (head, end of body, etc.)
  useEffect(() => {
    const scriptContainer = document.getElementById("__next");
    if (extractedScript && scriptContainer) {
      const [, scriptContent] = extractedScript;
      const script = document.createElement("script");
      const wrapper = `(function _(){${scriptContent}})()`;
      script.type = "text/javascript";
      script.append(wrapper);

      setTimeout(() => {
        scriptContainer.appendChild(script);
      });

      return () => {
        if (scriptContainer.contains(script)) {
          scriptContainer.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: SSR
          ? sanitize(parsedHtml, {
              FORCE_BODY: true,
              ADD_TAGS: ["style", "link"],
            })
          : parsedHtml,
      }}
    />
  );
};

export default CustomHTML;
