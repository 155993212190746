import { fetcherWithHeaders } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { setAuthorizationHeaders } from "../../app/Authorization";

export interface SignInData {
  email: string;
  password: string;
  code?: string;
}

const signInBaseUrl = "tokens";

export function signIn(payload: SignInData) {
  const decoratedPayload = {
    data: {
      attributes: payload,
    },
  };
  const request = () =>
    APIV3.post<JSONAPIDocument<SignInData>>(signInBaseUrl, {
      body: JSON.stringify(decoratedPayload),
    });
  return {
    json: async () => {
      const response = await fetcherWithHeaders(request, serializers.token);
      setAuthorizationHeaders(response.headers);
      return response;
    },
    fallbackKey: signInBaseUrl,
  };
}
