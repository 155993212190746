import { styled } from "@mui/material";
import { MediaSetting } from "../../builder/themeOptionsBuilder";

export interface DefaultContainerProps {
  paddingDesktop?: boolean;
}

export const Container = styled("div")<DefaultContainerProps>(({
  theme,
  paddingDesktop = false,
}) => {
  const { [theme.breakpoints.up("md")]: md, ...medias } =
    theme.getMediaForSettings(() => ({
      maxWidth: MediaSetting.maxWidth,
    }));
  return {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      ...(paddingDesktop ? {} : { paddingLeft: "0", paddingRight: "0" }),
      ...md,
    },
    ...medias,
  };
});
