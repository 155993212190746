import {
  Autocomplete,
  Chip,
  Icon,
  InputAdornment,
  styled,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useRouter } from "next/router";
import {
  useAddQueryParams,
  useQueryParams,
} from "../../../dataAccess/QueryParams";
import TextField from "../TextField";
import type { Settings as SearchSettings } from "../../public/Search";

type Props = Pick<SearchSettings, "alignment" | "multipleSearch" | "input">;

const SEARCH_QUERY_PARAM_KEY = "q";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    [theme.breakpoints.up("lg")]: {
      minWidth: "6rem !important",
    },
    flex: 1,
  },
  "& .MuiInputBase-root": {
    minHeight: "2.5rem",
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiInputBase-root": {
      paddingLeft: "1rem !important",
      paddingRight: "1rem !important",
      marginBottom: "0rem",
    },
  },
}));

const StyledChip = styled(Chip)(() => ({
  maxWidth: "10rem !important",
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  width: "1rem",
  marginRight: "0.5rem",
  "&:hover": {
    cursor: "pointer",
  },
}));

const StyledSearchIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.inputDefault,
}));

type Value = string | string[];

const SearchBar = ({ multipleSearch = false, alignment, input }: Props) => {
  const router = useRouter();
  const setQueryParams = useAddQueryParams();
  const { q } = useQueryParams();

  const searchValueFromQueryParam = useMemo(() => {
    if (q) {
      return multipleSearch ? q.split(",") : q;
    }
    return multipleSearch ? [] : "";
  }, [router]);

  const [searchValue, setSearchValue] = useState<Value>(
    searchValueFromQueryParam,
  );
  const [inputValue, setInputValue] = useState(!multipleSearch && q ? q : "");

  const setSearchQuery = (value: string) => {
    setQueryParams([
      {
        paramKey: "page_number",
        paramValue: null,
      },
      {
        paramKey: SEARCH_QUERY_PARAM_KEY,
        paramValue: value.length ? value : null,
      },
    ]);
  };

  const onSearchClick = (currentValue?: Value) => {
    if (!multipleSearch) {
      setSearchQuery(inputValue);
    } else {
      setSearchValue((prevValue) => {
        const newValue = [...(prevValue as string[]), inputValue];
        if (!currentValue && !inputValue) {
          return prevValue;
        }
        const value = currentValue || newValue;
        setSearchQuery(value.toString());
        return value;
      });
    }
  };

  return (
    <Autocomplete
      multiple={multipleSearch}
      value={searchValue}
      onChange={(e, newValue) => {
        if (!multipleSearch && newValue === null) {
          setInputValue("");
          return;
        }
        onSearchClick(newValue || "");
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      limitTags={2}
      options={[]}
      freeSolo
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <StyledChip
            {...getTagProps({ index })}
            key={index}
            label={option}
            title={option}
          />
        ))
      }
      renderInput={(params) => (
        <StyledTextField
          {...params}
          settingsOverride={input}
          placeholder="Search"
          onKeyDown={(e) => {
            if (e.key === "Enter" && inputValue === "" && q) {
              setSearchQuery("");
            }
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              textAlign: alignment === "left" ? "left" : "right",
            },
          }}
          InputProps={{
            ...params.InputProps,
            ...(alignment === "left"
              ? {
                  startAdornment: (
                    <>
                      <StyledAdornment
                        onClick={() => onSearchClick()}
                        position="end"
                      >
                        <StyledSearchIcon>search</StyledSearchIcon>
                      </StyledAdornment>
                    </>
                  ),
                }
              : {}),
            endAdornment: (
              <>
                {inputValue && !multipleSearch && (
                  <StyledAdornment
                    onClick={() => {
                      setInputValue("");
                      setQueryParams([
                        {
                          paramKey: SEARCH_QUERY_PARAM_KEY,
                          paramValue: null,
                        },
                      ]);
                    }}
                    position="end"
                  >
                    <Icon>close</Icon>
                  </StyledAdornment>
                )}
                {alignment !== "left" && (
                  <StyledAdornment
                    onClick={() => onSearchClick()}
                    position="end"
                  >
                    <StyledSearchIcon>search</StyledSearchIcon>
                  </StyledAdornment>
                )}
                {alignment === "left" && params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;
