import { FunctionComponent } from "react";
import Link from "@mui/material/Link";

interface Props {
  showLinks?: boolean;
}

const TermsText: FunctionComponent<Props> = ({ showLinks = true }) =>
  showLinks ? (
    <>
      By continuing you accept our{" "}
      <Link
        href="https://www.builtfirst.com/terms-and-condiitons"
        target="_blank"
      >
        Terms and Conditions
      </Link>{" "}
      and{" "}
      <Link
        href={showLinks ? "https://www.builtfirst.com/privacy-policy" : ""}
        target="_blank"
      >
        Privacy Policy
      </Link>
    </>
  ) : (
    <></>
  );

export default TermsText;
