import { FunctionComponent, useCallback, useMemo } from "react";
import { useQueryParams } from "../../dataAccess/QueryParams";
import {
  Collection,
  CollectionHeader,
  useCollections,
} from "../../dataAccess/api/collections";
import Filter, { MobileFilterProps, Option } from "../ui/Filter";
import { GridSettings } from "./Grid";
import { PublicComponentProps } from "./PublicComponentProps";
import { Settings as FilterSettings } from "./PartnersFilter";

interface Settings extends FilterSettings {
  hideHeadersWhenVertical?: boolean;
}

// TODO: remove "any" type in the next lines
interface Props
  extends MobileFilterProps,
    PublicComponentProps<Settings, any, any, GridSettings> {
  collections?: CollectionHeader[];
}

const loadingCollectionHeader: CollectionHeader[] = [
  { id: -1, name: "", collections: [] },
];

const CollectionsFilter: FunctionComponent<Props> = ({
  collections,
  settings,
  mobile,
}: Props) => {
  const { collection_ids } = useQueryParams();
  const { loadingCollectionList } = useCollections();
  const collectionToOption = useCallback(
    ({
      id: value,
      name: label,
      numberOfItems,
      ...collection
    }: Collection): Option => ({
      value: String(value),
      label,
      detailsLabel: settings.showNumberOfResults
        ? String(numberOfItems || "")
        : "",
      subOptions: collection.subCollections?.map((subCollection) =>
        collectionToOption(subCollection),
      ),
    }),
    [settings],
  );
  const defaultExpanded = useMemo(() => {
    if (collection_ids && collection_ids?.length > 0) {
      return true;
    }
    return mobile ? settings.isOpenOnMobile : settings.isOpenOnDesktop;
  }, [collection_ids]);

  return collections?.length || loadingCollectionList ? (
    <Filter
      alignment={settings.alignment}
      options={(collections || loadingCollectionHeader).map(
        ({ name, collections }) => ({
          title: settings.hideHeadersWhenVertical ? undefined : name,
          values: collections?.map(collectionToOption) || [],
        }),
      )}
      title={settings.title || "Collections"}
      queryParam="collection_ids"
      variant={
        settings.position !== "vertical" ? "horizontal" : settings.position
      }
      mobile={mobile}
      defaultExpanded={defaultExpanded}
      loading={loadingCollectionList}
    />
  ) : null;
};

export default CollectionsFilter;
