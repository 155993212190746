import useSWR from "swr";
import { defaultFetcher } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { buildUrlWithQueryParams } from "../QueryParams";

export interface Collection {
  id: number;
  name: string;
  description?: string;
  numberOfItems?: number;
  subCollections?: Omit<Collection, "subCollections">[];
}

export interface CollectionHeader {
  id: number;
  name: string;
  collections?: Collection[];
}

const base = `/items/collection_headers`;

export function getCollections() {
  const url = buildUrlWithQueryParams(base, {
    include: "collections.sub_collections",
    "fields[collection_header]": "name,collections",
    "fields[collection]": "name,number_of_items,description, sub_collections",
  });
  const request = () => APIV3.get<JSONAPIDocument<CollectionHeader[]>>(url);
  return {
    json: () => defaultFetcher(request, serializers.collection_header),
    fallbackKey: url,
  };
}

export function useCollections() {
  const { fallbackKey, json } = getCollections();
  const { data, isValidating } = useSWR(fallbackKey, json);
  return {
    loadingCollectionList: isValidating,
    collectionList: data,
  };
}
