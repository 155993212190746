import { useEffect, useState } from "react";

export function useReRenderOnResize() {
  const [, setState] = useState(false);
  useEffect(() => {
    function handleResize() {
      setState((current) => !current);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
}
