import { Popover, PopoverProps, styled } from "@mui/material";
import { FunctionComponent, useContext, useMemo } from "react";
import { EditorCtx } from "../../wysiwyg/EditorCtx";

interface Props {
  children: React.ReactNode;
  anchorEl: HTMLButtonElement | null;
  position: AxisPosition;
  open: boolean;
  onClose: PopoverProps["onClose"];
}

export interface AxisPosition {
  x: number;
  y: number;
}

export const popupElevation = 3;
const StyledPopper = styled(Popover)<{
  canvasWidth: number;
  canvasHeight: number;
}>(({ canvasWidth, canvasHeight }) => ({
  width: "100%",
  maxWidth: canvasWidth || "60rem",
  maxHeight: canvasHeight || "60rem",
}));
const ContentContainer = styled("div")<{
  isEditor?: boolean;
}>(({ isEditor }) => ({
  padding: "0.5rem",
  minWidth: isEditor ? "20rem" : 0,
}));

const DefaultPoper: FunctionComponent<Props> = ({
  children,
  anchorEl,
  position,
  open,
  onClose,
}) => {
  const { isEditor } = useContext(EditorCtx);
  const { width, height } = useMemo(() => {
    let left = 0;
    let top = 0;
    let width = 0;
    let height = 0;
    if (isEditor) {
      const canvasContent = document.getElementById("canvas-content");
      if (canvasContent) {
        const {
          x,
          y,
          width: canvasWidth,
          height: canvasHeight,
        } = canvasContent.getBoundingClientRect();
        left = x;
        top = y;
        width = canvasWidth * 1.5;
        height = canvasHeight * 1.5;
      }
    }
    return { left, top, width, height };
  }, [isEditor]);
  return (
    <StyledPopper
      open={open}
      onClose={onClose}
      {...(anchorEl && !isEditor
        ? {
            anchorEl,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        : {
            anchorReference: "anchorPosition",
            anchorPosition: { top: position.y, left: position.x },
          })}
      canvasHeight={height}
      canvasWidth={width}
    >
      <ContentContainer
        isEditor={isEditor}
        onClick={() => {
          // Trigger resize to refresh popover size if changes so it not goes outside the page
          setTimeout(() => {
            const resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
          });
        }}
      >
        {children}
      </ContentContainer>
    </StyledPopper>
  );
};

export default DefaultPoper;
