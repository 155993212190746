import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from "@mui/material";
import { FunctionComponent, ReactNode, useRef, useState } from "react";
import { TextButton } from "./TextButton";

export interface DropdownItem {
  value: string;
  label: string | ReactNode;
  icon?: ReactNode;
}

interface Props<T> {
  options: T[];
  onChange: (event: SelectChangeEvent<T>) => void;
  selectedValue: T;
  label: string | ReactNode;
  icon?: ReactNode;
  showIconAsLabel?: boolean;
}

const HiddenSelect = styled(Select<DropdownItem>)(() => ({
  display: "none",
}));

const OptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: ".5rem",
  color: theme.palette.text.paper,
}));

const StyledTypography = styled(Typography)(() => ({
  textTransform: "none",
}));

export const StyledTextButton = styled(TextButton)(({ theme }) => ({
  lineHeight: 0,
  paddingLeft: 0,
  paddingRight: 0,
  "& .MuiButton-startIcon": {
    margin: 0,
  },
  "& .MuiButton-endIcon": {
    margin: 0,
  },
  [theme.breakpoints.up("sm")]: {
    lineHeight: 0,
    "& .MuiButton-startIcon": {
      marginRight: "0.2rem",
    },
    "& .MuiButton-endIcon": {
      marginLeft: "0.2rem",
    },
  },
}));

const DropdownList: FunctionComponent<Props<DropdownItem>> = ({
  options,
  onChange,
  selectedValue,
  label,
  icon,
  showIconAsLabel,
}) => {
  const [open, setOpen] = useState(false);
  const openMenuRef = useRef(null);

  return (
    <>
      <StyledTextButton
        startIcon={selectedValue?.icon}
        endIcon={!selectedValue.value && icon}
        onClick={() => setOpen(true)}
        ref={openMenuRef}
      >
        {!showIconAsLabel && selectedValue.value && (
          <StyledTypography>{selectedValue.label}</StyledTypography>
        )}
        {!selectedValue.value && label}
      </StyledTextButton>
      <HiddenSelect
        open={open}
        onClose={() => setOpen(false)}
        MenuProps={{
          anchorEl: openMenuRef.current,
        }}
        value={selectedValue}
        onChange={onChange}
      >
        {options.map(({ value, label, icon }) => (
          <MenuItem key={value} value={value}>
            <OptionContainer>
              {icon}
              {label}
            </OptionContainer>
          </MenuItem>
        ))}
      </HiddenSelect>
    </>
  );
};

export default DropdownList;
