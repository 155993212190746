import { CSSObject, styled, Typography } from "@mui/material";

export const TypographyWithEllipsis = styled(Typography)<{
  clamp?: number;
  onlyDesktop?: boolean;
}>(({ clamp, onlyDesktop, theme }) => {
  const styles: CSSObject = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: clamp || 3,
    WebkitBoxOrient: "vertical",
  };
  return onlyDesktop
    ? {
        [theme.breakpoints.up("md")]: styles,
      }
    : styles;
});
