import { MouseEvent, MouseEventHandler, ReactNode } from "react";
import { NeutralAnchor } from "./NeutralAnchor";

interface Props {
  children: ReactNode;
  href: string;
  onClick?: MouseEventHandler;
}
export function SeoAnchor({ children, href, onClick }: Props) {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick && onClick(e);
  };

  return (
    <NeutralAnchor href={href} onClick={handleClick}>
      {children}
    </NeutralAnchor>
  );
}
